// import "./i18n";

import React, { Component } from "react";

import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import PageLoader from "../../components/common/PageLoader";

export default class index extends Component {
  state = { loaded: false };

  async componentDidMount() {
    await i18n
      .use(Backend)
      .use(initReactI18next)
      .init({
        lng: window.navigator.language.slice(0, 2),
        backend: {
          /* translation file path */
          loadPath: "/i18n/{{lng}}.json",
        },
        fallbackLng: "en",
        debug: false,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */

        keySeparator: false,
        interpolation: {
          escapeValue: false,
          formatSeparator: ",",
        },
        react: {
          wait: true,
          useSuspense: false,
        },
      });
    this.setState({ loaded: true });
  }

  render() {
    const { children } = this.props;
    const { loaded } = this.state;

    return loaded ? children : <PageLoader showText={false} />;
  }
}
