import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Company from "./containers/Company/reducer";
import Language from "./containers/Language/reducer";
import Location from "./containers/Location/reducer";
import Cards from "./containers/Cards/reducer";
import Dashboard from "./containers/Dashboard/reducer";
import Collections from "./containers/Collections/reducer";

const createRootReducer = (history) =>
  combineReducers({
    Location,
    router: connectRouter(history),
    Company,
    Language,
    Cards,
    Dashboard,
    Collections,
  });

export default createRootReducer;
