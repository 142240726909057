// @flow
import { all } from "redux-saga/effects";

import companySaga from "./containers/Company/sagas";
import languageSaga from "./containers/Language/sagas";
import locationSagas from "./containers/Location/sagas";
import dashboardSagas from "./containers/Dashboard/sagas";

export default function* rootSaga(getState) {
  yield all([locationSagas(), companySaga(), languageSaga(), dashboardSagas()]);
}
