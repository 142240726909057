import produce from "immer";

// The initial state of the App
export const initialState = {};

/* eslint-disable default-case, no-param-reassign */
const translatorReducer = (state = initialState, action) =>
  produce(state, (draft) => {});

export default translatorReducer;
