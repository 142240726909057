import React from "react";
import ReactDOM from "react-dom";
import { init as SentryInit } from "@sentry/browser";
import { Provider } from "react-redux";

import App from "./containers/App";
import reportWebVitals from "./reportWebVitals";
import { configureStore } from "./store";

if (process.env.NODE_ENV !== "development") {
  document.domain = "duuca.com";
  SentryInit({
    dsn: process.env.SENTRY_DSN,
  });
}

export const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
