import produce from "immer";

import * as constants from "./constants";

const initialState = {
  entities: {},
  simplified_entities: [],
};

const cards = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case constants.DELETE_CARD_SUCCESS:
        delete draft.entities[action.payload.id];
        break;
      case constants.RETRIEVE_CARD_SUCCESS:
        draft.entities[action.payload.id] = action.payload;
        break;
      case constants.RETRIEVE_CARD_LIST_FOR_CONNECTIONS_SUCCESS:
        draft.simplified_entities = action.payload;
        break;
      case constants.EDIT_CARD_SUCCESS:
        Object.assign(draft.entities[action.payload.id], action.payload);
        break;
      case constants.RETRIEVE_CARDS_SUCCESS:
        for (const id in action.payload) {
          if (draft.entities[id]) {
            Object.assign(draft.entities[id], action.payload[id]);
          } else draft.entities[id] = action.payload[id];
        }
        break;
      case constants.CREATE_CARD_SUCCESS:
        draft.entities[action.payload.id] = action.payload;
        break;
      case constants.CREATE_CARDS_FROM_TITLES_SUCCESS:
        for (const card of action.payload) {
          draft.entities[card.id] = card;
        }
        break;
      default:
        return;
    }
  });

export default cards;
