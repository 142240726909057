import produce from "immer";
import {
  LOAD_COMPANY_SUCCESS,
  LOAD_COMPANY,
  LOAD_COMPANY_ERROR,
} from "./constants";

// The initial state of the App
export const initialState = {
  loading: false,
  error: false,
  loaded: false,
  data: null,
};

/* eslint-disable default-case, no-param-reassign */
const companyReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_COMPANY:
        draft.loading = true;
        draft.error = false;
        break;

      case LOAD_COMPANY_SUCCESS:
        draft.data = action.payload;
        draft.loading = false;
        draft.loaded = true;
        draft.error = false;
        break;

      case LOAD_COMPANY_ERROR:
        draft.error = action.payload;
        draft.loading = false;
        break;
    }
  });

export default companyReducer;
