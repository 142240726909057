import { put, select, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";

import { PUSH_PREVIOUS_REGULAR_ROUTE } from "./constants";

export function* pushPreviousRegularRoute({ payload }) {
  try {
    const { lastRegularLocation } = yield select(({ Location }) => Location);
    yield put(
      push(
        {
          pathname: lastRegularLocation.pathname,
          search: lastRegularLocation.search,
        },
        payload
      )
    );
  } catch (err) {}
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* locationSagas() {
  yield takeLatest(PUSH_PREVIOUS_REGULAR_ROUTE, pushPreviousRegularRoute);
}
