// import { take, takeEvery, select, put } from "redux-saga/effects";

// import {
//   EDIT_CARD,
//   EDIT_CARD_ERROR,
//   EDIT_CARD_SUCCESS,
// } from "../Cards/constants";
// import { selectCard } from "../Cards/selectors";
// import { CARD_ADDED_TO_COLLECTION } from "./constants";

// function* interceptCardEdit(action) {
//   if (action.payload.newData.collection) {
//     const card = yield select(selectCard(action.payload.id));
//     if (!card.collection) {
//       const postAction = yield take([EDIT_CARD_SUCCESS, EDIT_CARD_ERROR]);
//       if (postAction.type === EDIT_CARD_SUCCESS) {
//         console.log(postAction);
//         yield put({
//           type: CARD_ADDED_TO_COLLECTION,
//           payload: { id: action.payload.id },
//         });
//       }
//     }
//   }
// }

export default function* dashboardSaga() {
  // yield takeEvery(EDIT_CARD, interceptCardEdit);
}
