import produce from "immer";

import * as constants from "./constants";

const initialState = {
  firstLoad: false,
  overview: createFilters(
    "all",
    "created_by_me",
    "favorites",
    "my_collections",
    "recently_viewed",
    "without_collection"
  ),
  apiCardQueries: {},
};

const dashboard = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // DASHBOARD OVERVIEW REDUCER
      case constants.RETRIEVE_INITIAL_DASHBOARD_OVERVIEW:
        draft.firstLoad = true;
        for (const key in action.payload) {
          draft.overview[key].objects = action.payload[key].objects;
          draft.overview[key].count = action.payload[key].total;
          ++draft.overview[key].page;
        }
        break;
      case constants.RETRIEVE_OVERVIEW_ITEM_PAGE_SUCCESS:
        draft.overview[action.payload.key].objects.push(
          ...action.payload.results
        );
        draft.overview[action.payload.key].count = action.payload.count;
        ++draft.overview[action.payload.key].page;
        break;
      // case CREATE_CARD_SUCCESS:
      //   const card = action.payload;
      //   const dataKeysToUpdate = ["all", "created_by_me", "recently_viewed"];

      //   if (!card.collection) dataKeysToUpdate.push("without_collection");
      //   else dataKeysToUpdate.push("my_collections");

      //   for (const key of dataKeysToUpdate) {
      //     draft.data[key].objects.unshift(card.id);
      //     ++draft.data[key].total;
      //   }
      //   break;
      // case constants.CARD_ADDED_TO_COLLECTION:
      //   const index = draft.data.without_collection.objects.findIndex(
      //     (id) => id === action.payload.id
      //   );
      //   if (index >= 0) {
      //     draft.data.without_collection.objects =
      //       draft.data.without_collection.objects.filter((_, i) => i !== index);
      //     draft.data.my_collections.objects.unshift(action.payload.id);
      //   }
      //   break;
      case constants.RETRIEVE_FILTER_PAGE:
        if (!draft.apiCardQueries[action.payload.query])
          draft.apiCardQueries[action.payload.query] = createQueryObject();
        draft.apiCardQueries[action.payload.query].loading = true;
        break;
      case constants.RETRIEVE_FILTER_PAGE_SUCCESS:
        const filterItem = draft.apiCardQueries[action.payload.query];
        filterItem.loading = false;
        filterItem.page = action.payload.page;
        filterItem.count = action.payload.result.count;
        filterItem.results.push(...action.payload.result.results);
        break;
      default:
        break;
    }
  });

function createFilters(...options) {
  return options.reduce((acc, item) => {
    acc[item] = { objects: [], count: 0, page: 0 };
    return acc;
  }, {});
}

function createQueryObject() {
  return { results: [], count: 0, page: 0 };
}

export default dashboard;
