// DASHBOARD OVERVIEW CONSTANTS
export const RETRIEVE_INITIAL_DASHBOARD_OVERVIEW =
  "DASHBOARD/RETRIEVE_INITIAL_DASHBOARD_OVERVIEW";
export const RETRIEVE_OVERVIEW_ITEM_PAGE_SUCCESS =
  "DASHBOARD/RETRIEVE_OVERVIEW_ITEM_PAGE_SUCCESS";

export const CARD_ADDED_TO_COLLECTION = "DASHBOARD/CARD_ADDED_TO_COLLECTION";

export const RETRIEVE_FILTER_PAGE = "DASHBOARD/RETRIEVE_FILTER_PAGE";
export const RETRIEVE_FILTER_PAGE_SUCCESS =
  "DASHBOARD/RETRIEVE_FILTER_PAGE_SUCCESS";
