import Axios, { AxiosRequestConfig } from "axios";

const api = Axios.create({
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export default api;

export const get = (url: string, config?: AxiosRequestConfig) =>
  api.get(url, config).then(({ data }) => data);
