import produce from "immer";
import { merge } from "lodash";
import SecureLS from "secure-ls";

import * as constants from "./constants";

const ls = new SecureLS();

export const initialState = {
  entities: {
    collections: {
      loading: false,
      error: false,
    },
    folders: {},
  },
};

const collections = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case constants.LOAD_COLLECTION:
      case constants.LOAD_COLLECTIONS:
        Object.assign(draft.entities.collections, {
          loading: true,
          error: false,
        });
        break;

      case constants.LOAD_COLLECTION_SUCCESS:
      case constants.LOAD_COLLECTIONS_SUCCESS:
        merge(draft.entities.collections, {
          ...action.payload,
          loading: false,
          error: false,
        });
        break;

      case constants.LOAD_COLLECTION_ERROR:
      case constants.LOAD_COLLECTIONS_ERROR:
        Object.assign(draft.entities.collections, {
          loading: false,
          error: true,
        });
        break;

      case constants.CREATE_COLLECTION_SUCCESS:
        draft.entities.collections[action.payload.id] = action.payload;
        break;

      case constants.EDIT_COLLECTION_SUCCESS:
        merge(draft.entities.collections, action.payload);
        break;

      case constants.DELETE_COLLECTION_SUCCESS:
        delete draft.entities.collections[action.payload.id];
        break;

      case constants.LOAD_FOLDERS_SUCCESS:
        merge(draft.entities.folders, action.payload);
        break;

      case constants.CREATE_FOLDER_SUCCESS:
        draft.entities.folders[action.payload.id] = action.payload;
        break;

      case constants.EDIT_FOLDER_SUCCESS:
        Object.assign(
          draft.entities.folders[action.payload.id],
          action.payload
        );
        break;

      case constants.DELETE_FOLDER_SUCCESS:
        delete draft.entities.folders[action.payload.id];
        break;

      default:
        return;
    }

    const { loading, error, ...collectionsInfo } = draft.entities.collections;
    ls.set("collectionsInfo", collectionsInfo);
    ls.set("foldersInfo", draft.entities.folders);
  });

export default collections;
