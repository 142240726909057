import {
  LOAD_COMPANY,
  LOAD_COMPANY_ERROR,
  LOAD_COMPANY_SUCCESS,
} from './constants';

export function loadCompany(callback) {
  return {
    type: LOAD_COMPANY,
    payload: { callback },
  };
}

export function companyLoaded(data) {
  return {
    type: LOAD_COMPANY_SUCCESS,
    payload: data,
  };
}

export function companyLoadingError(error) {
  return {
    type: LOAD_COMPANY_ERROR,
    payload: error,
  };
}
