export const CREATE_CARD = "CARDS/CREATE_CARD";
export const CREATE_CARD_ERROR = "CARDS/CREATE_CARD_ERROR";
export const CREATE_CARD_SUCCESS = "CARDS/CREATE_CARD_SUCCESS";

export const CREATE_CARDS_FROM_TITLES = "CARDS/CREATE_CARDS_FROM_TITLES";
export const CREATE_CARDS_FROM_TITLES_ERROR =
  "CARDS/CREATE_CARDS_FROM_TITLES_ERROR";
export const CREATE_CARDS_FROM_TITLES_SUCCESS =
  "CARDS/CREATE_CARDS_FROM_TITLES_SUCCESS";

export const RETRIEVE_CARD = "CARDS/RETRIEVE_CARD";
export const RETRIEVE_CARD_ERROR = "CARDS/RETRIEVE_CARD_ERROR";
export const RETRIEVE_CARD_SUCCESS = "CARDS/RETRIEVE_CARD_SUCCESS";

export const RETRIEVE_CARD_LIST_FOR_CONNECTIONS_SUCCESS =
  "CARDS/RETRIEVE_CARD_LIST_FOR_CONNECTIONS_SUCCESS";

export const DELETE_CARD = "CARDS/DELETE_CARD";
export const DELETE_CARD_ERROR = "CARDS/DELETE_CARD_ERROR";
export const DELETE_CARD_SUCCESS = "CARDS/DELETE_CARD_SUCCESS";

export const EDIT_CARD = "CARDS/EDIT_CARD";
export const EDIT_CARD_ERROR = "CARDS/EDIT_CARD_ERROR";
export const EDIT_CARD_SUCCESS = "CARDS/EDIT_CARD_SUCCESS";

export const RETRIEVE_CARDS = "CARDS/RETRIEVE_CARDS";
export const RETRIEVE_CARDS_ERROR = "CARDS/RETRIEVE_CARDS_ERROR";
export const RETRIEVE_CARDS_SUCCESS = "CARDS/RETRIEVE_CARDS_SUCCESS";
