import React from "react";

import { matchPath } from "react-router-dom";

const routes = [
  // Páginas publicas
  {
    path: "/cadastrar/:language",
    component: React.lazy(() => import("../pages/signup")),
    // roleAccess: [2],
    public: true,
    name: "Signup",
  },
  {
    path: "/cadastrar",
    component: React.lazy(() => import("../pages/signup")),
    // roleAccess: [2],
    public: true,
    name: "Signup",
  },
  {
    path: "/contas/desinscrever/:user_token",
    component: React.lazy(() => import("../pages/emailUnsubscribe")),
    public: true,
  },
  {
    path: "/cards/public/:hash/",
    component: React.lazy(() => import("../pages/cards/view/external")),
    public: true,
  },
  // Paginas privadas
  {
    path: "/plano-expirado",
    component: React.lazy(() => import("../pages/expired")),
    hideDashboard: true,
  },
  {
    path: "/assistente",
    component: React.lazy(() => import("../pages/assistant")),
    hideDashboard: true,
    name: "Assistente",
  },
  {
    path: "/criar-empresa",
    component: React.lazy(() => import("../pages/company/create")),
    hideDashboard: true,
    name: "Criar empresa",
  },
  {
    path: "/estatisticas/usuario",
    component: React.lazy(() => import("../pages/analytics/User")),
    name: "Estatisticas user",
  },
  {
    path: "/estatisticas/colecoes/:id(\\d+)",
    component: React.lazy(() => import("../pages/analytics/Collections")),
  },
  {
    path: "/estatisticas",
    component: React.lazy(() => import("../pages/analytics/index")),
    name: "Estatisticas",
  },
  {
    path: "/historico",
    component: React.lazy(() => import("../pages/companyLogs")),
  },
  {
    path: "/configuracoes",
    component: React.lazy(() => import("../pages/company/settings")),
  },
  {
    path: "/competencias",
    component: React.lazy(() => import("../pages/company/abilitiesManagement")),
  },
  {
    path: "/gestao-de-assinatura",
    component: React.lazy(() =>
      import("../pages/company/membershipManagement")
    ),
  },
  {
    path: "/dados-de-pagamento",
    component: React.lazy(() => import("../pages/company/paymentDetails")),
  },
  {
    path: "/atribuicoes",
    component: React.lazy(() => import("../pages/myAttributions")),
  },
  {
    path: "/workflows/my",
    component: React.lazy(() => import("../pages/workflows/my")),
  },
  {
    path: "/workflows",
    component: React.lazy(() => import("../pages/workflows/all")),
  },
  // Treinamentos
  {
    path: "/treinamentos",
    component: React.lazy(() => import("../routes/CoursesRouter")),
  },
  {
    path: "/dashboard",
    component: React.lazy(() => import("../pages/dashboard")),
  },
  {
    path: "/editar-cadastro",
    component: React.lazy(() => import("../pages/accountSettings")),
  },
  {
    path: "/colaboradores",
    component: React.lazy(() => import("../pages/company/members")),
  },
  {
    path: '/conexoes',
    component: React.lazy(() => import('../pages/connections/')),
  },
  // Colecoes
  {
    path: "/colecoes/:id(\\d+)",
    component: React.lazy(() => import("../pages/collections/item")),
  },
  // Perfil
  {
    path: "/perfil/:id",
    component: React.lazy(() => import("../pages/profile")),
    hasTour: true,
  },
  {
    path: "/perfil",
    component: React.lazy(() => import("../pages/profile")),
    hasTour: true,
  },
  // Trilhas
  {
    path: "/trilhas",
    component: React.lazy(() => import("../pages/paths")),
  },
  // Colecoes
  {
    path: "/colecoes",
    component: React.lazy(() => import("../pages/collections")),
  },
  {
    path: "/cards",
    component: React.lazy(() => import("../pages/cards")),
  },
  // Busca
  {
    path: "/search",
    component: React.lazy(() => import("../pages/search")),
  },
];

export const modalRoutes = [
  {
    path: "/workflows/create",
    component: React.lazy(() => import("../pages/workflows/Create")),
  },
  {
    path: "/workflows/:id(\\d+)/edit",
    component: React.lazy(() => import("../pages/workflows/Edit")),
  },
  {
    path: "/treinamentos/selecionar",
    component: React.lazy(() => import("../pages/courses/createSelector")),
  },
  {
    path: "/cards/criar",
    component: React.lazy(() => import("../pages/cards/create")),
  },
  {
    path: "/cards/:id(\\d+)/",
    component: React.lazy(() => import("../pages/cards/view")),
  },
  {
    path: "/cards/share",
    component: React.lazy(() => import("../pages/cards/shareLink")),
  },
  {
    path: "/upgrade",
    component: React.lazy(() => import("../pages/upgradeSubscription")),
  },
  {
    path: "/treinamentos/externos/criar",
    component: React.lazy(() => import("../pages/courses/externalEdit")),
  },
  {
    path: "/treinamentos/externos/editar/:course_id(\\d+)",
    component: React.lazy(() => import("../pages/courses/externalEdit")),
  },
  {
    path: "/treinamentos/externos/:course_id(\\d+)",
    component: React.lazy(() => import("../pages/courses/external")),
  },
  {
    path: "/trilhas/criar",
    component: React.lazy(() => import("../pages/paths/item")),
  },
  {
    path: "/trilhas/:id(\\d+)",
    component: React.lazy(() => import("../pages/paths/item")),
  },
  {
    path: "/habilidades/adicionar",
    component: React.lazy(() => import("../pages/skillCreate")),
  },
  {
    path: "/colecoes/:id(\\d+)/editar",
    component: React.lazy(() => import("../pages/collections/edit")),
  },
  {
    path: "/colecoes/criar",
    component: React.lazy(() => import("../pages/collections/create")),
  },
];

export default routes;

export const routesWithTour = routes.filter((item) => item.hasTour);

export function routesInclude(routeList, pathname) {
  return routeList.reduce(
    (found, route) => found || matchPath(pathname, route.path),
    false
  );
}
