import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";

import LogoAnimation from "./LogoAnimation";

export default function PageLoader({ showText = true }) {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  });

  return ReactDOM.createPortal(
    <div
      css={`
        position: absolute;
        height: 100vh;
        width: 100vw;
        background: #ffffff33;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div className="text-center">
        <LogoAnimation />
        {showText && (
          <div className="mt-4">
            <h5
              css={`
                color: #333;
              `}
            >
              {t("loading.label")}...
            </h5>
          </div>
        )}
      </div>
    </div>,
    document.body
  );
}
