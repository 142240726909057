import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import SecureLS from "secure-ls";
import { normalize } from "normalizr";

import { LOAD_COMPANY } from "./constants";
import { HTTP_RESPONSE_ERROR } from "../App/constants";
import { companyLoaded, companyLoadingError } from "./actions";
import api from "../../services/api";
import { collection as CollectionSchema } from "../Collections/schemas";
import { collectionsLoaded, foldersLoaded } from "../Collections/actions";

const ls = new SecureLS();

/**
 * Função para requisitar a empresa do usuário corrente
 */
export function* getCompany({ payload }) {
  try {
    // Pega os dados da empresas salvos no local storage e atualiza a loja caso existirem
    const companyInfo = ls.get("companyInfo");
    if (companyInfo) yield put(companyLoaded(companyInfo));

    const collectionsInfo = ls.get("collectionsInfo");
    if (collectionsInfo) yield put(collectionsLoaded(collectionsInfo));

    const foldersInfo = ls.get("foldersInfo");
    if (foldersInfo) yield put(foldersLoaded(foldersInfo));

    // Faz a requisição dos dados da empresa para o backend e atualiza a loja após a conclusão da requisição
    const {
      data: { collections, ...company },
    } = yield call(api.get, `/empresas/get_company/`);

    const normalizedData = normalize(collections, [CollectionSchema]);

    yield put(companyLoaded(company));
    yield put(collectionsLoaded(normalizedData.entities.collections));
    yield put(foldersLoaded(normalizedData.entities.folders));

    // Salva os dados requisitados no local storage
    ls.set("companyInfo", company);
    ls.set("collectionsInfo", normalizedData.entities.collections);
    ls.set("foldersInfo", normalizedData.entities.folders);

    // Chama a callback passada para a saga, se existir
    if (payload && payload.callback) yield call(payload.callback);
  } catch (err) {
    ls.remove("companyInfo");
    ls.remove("collectionsInfo");
    ls.remove("foldersInfo");
    yield put(companyLoadingError(err));
  }
}

export function* checkSubscriptionError({ payload }) {
  if (payload?.response) {
    const { status, data } = payload.response;
    if (status === 403) {
      let error_type = data?.error_type
        ? typeof data.error_type === "string"
          ? JSON.loads(data.error_type)
          : data.error_type
        : [null];
      data.error_type = error_type;
      if (error_type[0] === "subscription") {
        yield put(push("/upgrade", data));
      }
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* userData() {
  yield takeLatest(LOAD_COMPANY, getCompany);
  yield takeLatest(HTTP_RESPONSE_ERROR, checkSubscriptionError);
}
