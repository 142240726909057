import { call, takeLatest } from "redux-saga/effects";
import i18n from "i18next";

import { LOAD_COMPANY_SUCCESS } from "../Company/constants";
import { SET_LANGUAGE } from "./constants";
import api from "../../services/api";

export function* updateLanguage({ payload: company }) {
  const userLanguage = company.member.language.slice(0, 2);
  if (i18n.language !== userLanguage) yield i18n.changeLanguage(userLanguage);
}

export function* setLanguage({ payload: language }) {
  if (i18n.language !== language)
    try {
      yield call(api.post, "/membros/change_language/", { language });
      document.location.reload();
      // i18n.changeLanguage(language);
    } catch (err) {
      console.log(err);
    }
}

export default function* languageData() {
  yield takeLatest(LOAD_COMPANY_SUCCESS, updateLanguage);
  yield takeLatest(SET_LANGUAGE, setLanguage);
}
