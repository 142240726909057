import React, { Fragment } from "react";
import posed from "react-pose";
import { tween } from "popmotion";
import { interpolate } from "flubber";

const paths = {
  duuca:
    "M62.2639 105.511C63.2407 105.334 64.2064 105.13 65.1602 104.902L116.628 214.361C96.214 229.107 84.9144 254.734 89.7096 281.165C94.1277 305.518 111.098 324.432 132.74 332.446L102.534 445.287C98.9061 444.984 95.1588 445.395 91.4862 446.617C76.9633 451.451 69.1086 467.143 73.9425 481.666C78.7763 496.189 94.4682 504.044 108.991 499.21C123.514 494.376 131.369 478.684 126.535 464.161C124.45 457.898 120.346 452.875 115.187 449.575L145.64 335.813C152.92 336.957 160.512 336.919 168.172 335.53C197.43 330.221 218.837 306.795 222.924 278.915L335.234 272.4C339.187 291.297 357.504 303.713 376.616 300.246C396.049 296.72 408.944 278.109 405.418 258.676C401.893 239.244 383.282 226.349 363.849 229.874C348.988 232.57 337.95 244.089 335.156 258.051L223.471 264.548C223.3 262.067 222.99 259.571 222.536 257.068C218.87 236.859 206.56 220.396 190.11 210.799L228.165 130.461C232.49 131.798 237.17 132.226 241.95 131.553C259.307 129.108 271.395 113.055 268.95 95.6982C266.504 78.3413 250.451 66.2531 233.095 68.6983C215.738 71.1436 203.65 87.1963 206.095 104.553C207.19 112.327 211.015 119.044 216.464 123.872L177.928 205.225C167.39 201.618 155.812 200.574 144.074 202.703C140.817 203.294 137.658 204.109 134.609 205.13L83.5416 96.5232C99.6016 85.0195 108.508 64.9246 104.747 44.1961C99.5474 15.533 72.0958 -3.48744 43.4328 1.71263C14.7697 6.91271 -4.25076 34.3642 0.949317 63.0273C6.1494 91.6903 33.6008 110.711 62.2639 105.511Z",
  bookmark:
    "M350.875 500C364.169 500 376.918 494.732 386.319 485.355C395.719 475.979 401 463.261 401 450V50C401 22.25 378.444 0 350.875 0H200.5V175L137.844 137.5L75.1875 175V0H50.125C36.831 0 24.0815 5.26784 14.6813 14.6447C5.28101 24.0215 0 36.7392 0 50V450C0 463.261 5.28101 475.979 14.6813 485.355C24.0815 494.732 36.831 500 50.125 500H350.875Z",
  brain:
    "M251.062 0C146.074 0 61.415 81.9444 56.1239 185L2.65512 255.278C-4.02847 263.889 2.65512 277.778 14.3514 277.778H56.1239V361.111C56.1239 391.944 80.9088 416.667 111.82 416.667H139.669V500H334.607V369.722C400.607 338.611 446 272.222 446 194.444C446 87.2222 359.113 0 251.062 0ZM364.126 182.5L309.543 196.944L349.645 237.222C359.392 246.667 359.392 262.778 349.645 272.5C339.898 282.222 323.746 282.222 314.278 272.5L273.619 232.5L259.416 286.944C256.075 300.556 242.15 308.333 229.062 304.722C215.416 301.111 207.619 287.5 211.239 274.167L225.998 219.444L171.137 234.167C157.77 237.778 143.846 230 140.504 216.389C136.884 203.333 144.681 189.444 158.327 186.111L212.91 171.944L172.808 131.389C163.061 121.944 163.061 106.111 172.808 96.1111C182.555 86.3889 198.707 86.3889 208.176 96.1111L248.556 136.111L263.037 81.6667C266.378 68.3333 280.024 60.5556 292.834 63.8889C306.759 67.5 314.556 81.1111 311.214 94.7222L296.455 149.167L351.037 134.444C364.683 131.111 378.329 138.889 381.949 152.778C385.291 165.556 377.493 179.167 364.126 182.5Z",
  folder:
    "M449.1 50.25H249.5L199.6 0H49.9C36.6657 0 23.9734 5.29418 14.6154 14.7179C5.2573 24.1416 0 36.9229 0 50.25V351.75C0 365.077 5.2573 377.858 14.6154 387.282C23.9734 396.706 36.6657 402 49.9 402H449.1C462.334 402 475.027 396.706 484.385 387.282C493.743 377.858 499 365.077 499 351.75V100.5C499 87.1729 493.743 74.3916 484.385 64.9679C475.027 55.5442 462.334 50.25 449.1 50.25V50.25ZM397.703 326.625L324.35 283.41L250.997 326.625L270.458 242.959L205.838 186.679L290.917 179.393L324.35 100.5L357.783 179.393L442.863 186.679L378.242 242.959L397.703 326.625Z",
  trophy:
    "M398.4 0C375.99 0 348.6 25 348.6 50H149.4C149.4 25 122.01 0 99.6 0H0V225C0 250 24.9 275 49.8 275H104.58C114.54 325 146.91 367.5 224.1 375V427C149.4 438.5 149.4 500 149.4 500H348.6C348.6 500 348.6 438.5 273.9 427V375C351.09 367.5 383.46 325 393.42 275H448.2C473.1 275 498 250 498 225V0H398.4ZM99.6 225H49.8V50H99.6V225ZM448.2 225H398.4V50H448.2V225Z",
  users:
    "M102.529 140.969C137.185 136.957 164.197 106.979 164.197 70.7127C164.197 31.7222 132.974 0 94.5979 0C56.2214 0 24.9988 31.7211 24.9988 70.7127C24.9998 107.584 52.9196 137.955 88.4074 141.148C42.9619 148.797 0.5 224.06 0.5 340.662V404.637C0.5 424.154 7.9619 439.98 17.167 439.98H483.833C493.037 439.98 500.5 424.157 500.5 404.637V340.662C500.499 224.318 458.22 149.145 412.887 141.217C448.764 138.421 477.115 107.867 477.115 70.7127C477.115 31.7222 445.892 0 407.516 0C369.139 0 337.917 31.7211 337.917 70.7127C337.917 106.793 364.653 136.65 399.053 140.905C372.584 144.374 346.426 171.442 328.277 227.228C310.972 174.039 284.692 145.313 257.686 140.998C292.74 137.101 320.099 107.066 320.099 70.7127C320.099 31.7222 288.627 0 249.943 0C211.26 0 179.787 31.7211 179.787 70.7127C179.787 107.305 207.507 137.496 242.893 141.071C216.021 145.633 189.919 174.37 172.721 227.228C154.702 171.843 128.796 144.759 102.529 140.969Z",
};

const pathIds = Object.keys(paths);

const morphTransition = ({ from, to }) =>
  tween({
    from: 0,
    to: 1,
  }).pipe(interpolate(from, to));

const Icon = posed.path(
  pathIds.reduce((config, id) => {
    config[id] = {
      d: paths[id],
      transition: morphTransition,
    };

    return config;
  }, {})
);

class LogoAnimation extends React.Component {
  state = { pathIndex: 0 };
  _isMounted = false;

  componentDidMount() {
    const interval = this.props.interval || 500;
    this._isMounted = true;
    this.intervalFunction = setInterval(() => {
      const { pathIndex } = this.state;
      const nextIndex = (pathIndex + 1) % pathIds.length;
      if (this._isMounted)
        this.setState({
          pathIndex: nextIndex,
        });
    }, interval);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.intervalFunction);
  }

  render() {
    const size = this.props.size || 50;
    return (
      <Fragment>
        <svg
          width={size}
          height={size}
          viewBox="0 0 520 500"
          style={{ fill: "#f46025" }}
        >
          <Icon pose={pathIds[this.state.pathIndex]} />
        </svg>
      </Fragment>
    );
  }
}

export default LogoAnimation;
