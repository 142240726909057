export const LOAD_COLLECTIONS = "COLLECTIONS/LOAD_COLLECTIONS";
export const LOAD_COLLECTIONS_SUCCESS = "COLLECTIONS/LOAD_COLLECTIONS_SUCCESS";
export const LOAD_COLLECTIONS_ERROR = "COLLECTIONS/LOAD_COLLECTIONS_ERROR";

export const LOAD_COLLECTION = "COLLECTIONS/LOAD_COLLECTION";
export const LOAD_COLLECTION_SUCCESS = "COLLECTIONS/LOAD_COLLECTION_SUCCESS";
export const LOAD_COLLECTION_ERROR = "COLLECTIONS/LOAD_COLLECTION_ERROR";

export const CREATE_COLLECTION = "COLLECTIONS/CREATE_COLLECTION";
export const CREATE_COLLECTION_ERROR = "COLLECTIONS/CREATE_COLLECTION_ERROR";
export const CREATE_COLLECTION_SUCCESS =
  "COLLECTIONS/CREATE_COLLECTION_SUCCESS";

export const EDIT_COLLECTION = "COLLECTIONS/EDIT_COLLECTION";
export const EDIT_COLLECTION_ERROR = "COLLECTIONS/EDIT_COLLECTION_ERROR";
export const EDIT_COLLECTION_SUCCESS = "COLLECTIONS/EDIT_COLLECTION_SUCCESS";

export const DELETE_COLLECTION = "COLLECTIONS/DELETE_COLLECTION";
export const DELETE_COLLECTION_ERROR = "COLLECTIONS/DELETE_COLLECTION_ERROR";
export const DELETE_COLLECTION_SUCCESS =
  "COLLECTIONS/DELETE_COLLECTION_SUCCESS";

export const LOAD_FOLDERS_SUCCESS = "COLLECTIONS/LOAD_FOLDERS_SUCCESS";

export const CREATE_FOLDER = "COLLECTIONS/CREATE_FOLDER";
export const CREATE_FOLDER_ERROR = "COLLECTIONS/CREATE_FOLDER_ERROR";
export const CREATE_FOLDER_SUCCESS = "COLLECTIONS/CREATE_FOLDER_SUCCESS";

export const EDIT_FOLDER = "COLLECTIONS/EDIT_FOLDER";
export const EDIT_FOLDER_ERROR = "COLLECTIONS/EDIT_FOLDER_ERROR";
export const EDIT_FOLDER_SUCCESS = "COLLECTIONS/EDIT_FOLDER_SUCCESS";

export const DELETE_FOLDER = "COLLECTIONS/DELETE_FOLDER";
export const DELETE_FOLDER_ERROR = "COLLECTIONS/DELETE_FOLDER_ERROR";
export const DELETE_FOLDER_SUCCESS = "COLLECTIONS/DELETE_FOLDER_SUCCESS";
