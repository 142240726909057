import { Dispatch } from "redux";
import { serialize } from "object-to-formdata";
import { normalize } from "normalizr";

import api from "../../services/api";
import * as constants from "./constants";
import {
  CollectionType,
  FolderType,
  PartialFolderType,
  CollectionListItemType,
  CollectionItemType,
} from "../../types/Collections";
import {
  collection as CollectionSchema,
  folder as FolderSchema,
} from "./schemas";

export const retrieveCollections = () => async (dispatch: Dispatch) => {
  dispatch({ type: constants.LOAD_COLLECTIONS, payload: {} });
  try {
    const {
      data: { results },
    }: { data: { results: CollectionListItemType[] } } = await api.get(
      "/collections/?page_size=100"
    );

    const normalizedResults = normalize(results, [CollectionSchema]);

    dispatch({
      type: constants.LOAD_COLLECTIONS_SUCCESS,
      payload: normalizedResults.entities.collections,
    });
  } catch (e) {
    dispatch({ type: constants.LOAD_COLLECTIONS_ERROR, payload: {} });

    throw e;
  }
};

export const retrieveCollection =
  (id: number) => async (dispatch: Dispatch) => {
    dispatch({ type: constants.LOAD_COLLECTION, payload: {} });
    try {
      const { data }: { data: CollectionItemType } = await api.get(
        `/collections/${id}/`
      );

      const normalizedResults = normalize([data], [CollectionSchema]);

      dispatch({
        type: constants.LOAD_COLLECTION_SUCCESS,
        payload: normalizedResults.entities.collections,
      });
    } catch (e) {
      dispatch({ type: constants.LOAD_COLLECTION_ERROR, payload: {} });

      throw e;
    }
  };

export const createCollection = (values: any) => async (dispatch: Dispatch) => {
  try {
    const { data }: { data: CollectionType } = await api.post(
      "/collections/",
      serialize(values),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    dispatch({ type: constants.CREATE_COLLECTION_SUCCESS, payload: data });
  } catch (e) {
    throw e;
  }
};

export const editCollection =
  (id: number, values: any) => async (dispatch: Dispatch) => {
    try {
      const { data }: { data: CollectionType } = await api.patch(
        `/collections/${id}/`,
        serialize(values)
      );

      const normalizedResults = normalize([data], [CollectionSchema]);

      dispatch({
        type: constants.EDIT_COLLECTION_SUCCESS,
        payload: normalizedResults.entities.collections,
      });
    } catch (e) {
      throw e;
    }
  };

export const deleteCollection = (id: number) => async (dispatch: Dispatch) => {
  try {
    await api.delete(`/collections/${id}/`);
    dispatch({ type: constants.DELETE_COLLECTION_SUCCESS, payload: { id } });
  } catch (e) {
    throw e;
  }
};

export const retrieveCollectionFolders =
  (id: number) =>
  async (dispatch: Dispatch): Promise<{ data: FolderType[] }> => {
    try {
      const { data }: { data: PartialFolderType[] } = await api.get(
        `/collections/folders/?collection=${id}`
      );

      const normalizedData = normalize(data, [FolderSchema]);

      dispatch({
        type: constants.LOAD_FOLDERS_SUCCESS,
        payload: normalizedData.entities.folders,
      });
      return { data };
    } catch (e) {
      throw e;
    }
  };

export const createFolder =
  (values: any, collection?: number) => async (dispatch: Dispatch) => {
    try {
      const { data }: { data: PartialFolderType } = await api.post(
        "/collections/folders/",
        {
          ...values,
          collection,
        }
      );
      dispatch({ type: constants.CREATE_FOLDER_SUCCESS, payload: data });
    } catch (e) {
      throw e;
    }
  };

export const editFolder =
  (id: number, values: any) => async (dispatch: Dispatch) => {
    try {
      const { data }: { data: PartialFolderType } = await api.patch(
        `/collections/folders/${id}/`,
        values
      );
      dispatch({ type: constants.EDIT_FOLDER_SUCCESS, payload: data });
    } catch (e) {
      throw e;
    }
  };

export const deleteFolder = (id: number) => async (dispatch: Dispatch) => {
  try {
    await api.delete(`/collections/folders/${id}/`);
    dispatch({ type: constants.DELETE_FOLDER_SUCCESS, payload: { id } });
  } catch (e) {
    throw e;
  }
};

// Ações para a saga de company
export function collectionsLoaded(data: any) {
  return {
    type: constants.LOAD_COLLECTIONS_SUCCESS,
    payload: data,
  };
}

export function foldersLoaded(data: any) {
  return {
    type: constants.LOAD_FOLDERS_SUCCESS,
    payload: data,
  };
}
