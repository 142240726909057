import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ConfigProvider } from "antd";
import ptBr from "antd/lib/locale/pt_BR";
import { useTranslation } from "react-i18next";

import routes from "../../lib/routes";
import PageLoader from "../../components/common/PageLoader";
import Language from "../Language";
import { catchResponseError } from "./actions";
import { history } from "../../store";

import "../../styles/antd.css";
import "../../styles/shed.css";
import "../../styles/index.scss";
import api from "../../services/api";

const User = React.lazy(() => import("./User"));

function AntdProvider(props) {
  const { i18n } = useTranslation();
  return (
    <ConfigProvider
      locale={i18n.language === "pt" ? ptBr : undefined}
      {...props}
    />
  );
}

class App extends React.Component {
  componentDidMount() {
    api.interceptors.response.use(undefined, (error) => {
      this.props.catchResponseError(error);
      return Promise.reject(error);
    });
  }
  render() {
    return (
      <Language>
        <AntdProvider>
          <ConnectedRouter history={history}>
            {/* <ScrollToTop> */}
            <React.Suspense fallback={<PageLoader />}>
              <Switch>
                {routes
                  .filter((route) => route.public)
                  .map((route) => (
                    <Route
                      path={route.path}
                      component={route.component}
                      key={route.path}
                    />
                  ))}
                <Route path="/" component={User} />
              </Switch>
            </React.Suspense>
            {/* </ScrollToTop> */}
          </ConnectedRouter>
        </AntdProvider>
      </Language>
    );
  }
}

export default connect(null, { catchResponseError })(App);
